import Flex from '@react-css/flex';
import { useQuery } from '@tanstack/react-query';
import download from 'downloadjs';
import useAlertQueue from 'hooks/useAlertQueue';
import qs from 'qs';
import { useLocation, useParams } from 'react-router';
import Card from 'storybook/stories/cells/Card';
import Body from 'storybook/stories/molecules/Body';
import Layout from 'storybook/stories/species/Layout';
import { getOTAPackingSlipForOrder } from 'utils/api/orders';
import type ApiError from 'utils/ApiError';

const OrderPackingSlipPage = () => {
  const { search } = useLocation();
  const alertQueue = useAlertQueue();
  const { orderId } = useParams<{ orderId: string }>();
  const tokenParam = qs.parse(search, { ignoreQueryPrefix: true }).token as string;

  const generatingPackingSlipForOrder = useQuery({
    queryKey: ['getOTAPackingSlipForOrder', orderId, tokenParam],
    // eslint-disable-next-line consistent-return
    queryFn: async ({ queryKey }) => {
      try {
        const blob = await getOTAPackingSlipForOrder(queryKey[1], queryKey[2]);
        return blob;
      } catch (err: any) {
        const maybeApiError: ApiError = err;
        if (maybeApiError?.responseStatus === 404) {
          alertQueue.addErrorAlert('Packing slip not found', maybeApiError?.message);
        } else {
          throw new Error(maybeApiError?.message);
        }
      }
    },
    enabled: !!orderId,
    onSuccess: (data) => {
      if (data) {
        download(new Blob([data]), 'packingslip.pdf', 'application/pdf');
      }
    },
    onError: (error: ApiError) => {
      alertQueue.addErrorAlert('Error download packing slip', error.message);
    },
  });

  return (
    <Layout.Root>
      <Layout.Content>
        <Card>
          <Flex column gap="24px">
            <Body variant="Body/Regular">
              {generatingPackingSlipForOrder.isLoading
                ? 'Loading...'
                : 'Your packing slip should download momentarily'}
            </Body>
          </Flex>
        </Card>
      </Layout.Content>
    </Layout.Root>
  );
};

export default OrderPackingSlipPage;
