/* eslint-disable no-nested-ternary */
import Flex from '@react-css/flex';

import { useMutation } from '@tanstack/react-query';
import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { Prompt, useHistory, useLocation, useParams } from 'react-router-dom';
import styled from 'styled-components';
import { useDocumentTitle } from 'usehooks-ts';

import Message, { useMessage } from 'components/Common/Message';
import DashboardPage from 'components/Dashboard/DashboardPage';
import useEditProduct from 'hooks/products/useEditProduct';
import useFetchProduct from 'hooks/products/useFetchProduct';
import useProductValidationErrors from 'hooks/products/useProductValidationErrors';
import useAlertQueue from 'hooks/useAlertQueue';
import useFlagEnabled from 'hooks/useFlagEnabled';
import Card from 'storybook/stories/cells/Card';
import Heading from 'storybook/stories/molecules/Heading';
import { patchBuyerProduct } from 'utils/api/buyer/products';
import { patchProduct } from 'utils/api/products';
import { ACCOUNT_TYPE_SELLER } from 'utils/constants';

import { selectMe } from 'store/selectors/me';
import breakpoints from 'utils/breakpoints';
import BuyerMetafields from './BuyerMetafields';
import LoadingProductPage from './LoadingProductPage';
import NoProductPage from './NoProductPage';
import ProductAttributes from './ProductAttributes';
import ProductCategory from './ProductCategory';
import ProductDescription from './ProductDescription';
import ProductDetails from './ProductDetails';
import ProductImages from './ProductImages';
import ProductOptions from './ProductOptions';
import ProductPageHeader from './ProductPageHeader';
import ProductTags from './ProductTags';
import ProductTitleDescription from './ProductTitleDescription';
import ProductValidationAlert from './ProductValidationAlert';
import ProductVariants from './ProductVariants';

const PageContainer = styled.div`
  max-width: 68rem;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 3rem;
`;

const FlexContainer = styled(Flex)`
  gap: 32px;
  ${breakpoints({
    values: {
      xs: {
        'flex-direction': 'column',
      },
      sm: {
        'flex-direction': 'column',
      },
      md: {
        'flex-direction': 'column',
      },
      lg: {
        'flex-direction': 'row',
      },
      xl: {
        'flex-direction': 'row',
      },
      xxl: {
        'flex-direction': 'row',
      },
    },
  })}
`;

const ProductPage = () => {
  const { productId } = useParams();
  const location = useLocation();
  const history = useHistory();
  const { addErrorAlert, addSuccessAlert } = useAlertQueue();

  const isProductMetafieldsEnabled = useFlagEnabled('showProductMetafields');

  const mainContentRef = useRef();
  const { company } = useSelector(selectMe);
  const { commerceType } = company || {};
  const { messageState, hideMessage } = useMessage();
  const [updating, setUpdating] = useState(false);

  const {
    product,
    isLoadingProduct,
    fetchProductError,
    invalidateProductQuery,
    removeProductQuery,
    setProductQueryData,
  } = useFetchProduct({ productId });

  const {
    edits,
    hasEdits,
    onEditProductField,
    onEditArrayField,
    onEditObjectField,
    onDiscardEdits,
    clearEditsAt,
  } = useEditProduct({ product });

  // Warn when leaving page with unsaved changes
  useEffect(() => {
    const onBeforeUnload = (e) => {
      // Current browsers
      e.preventDefault();
      // Legacy support
      // eslint-disable-next-line no-param-reassign
      e.returnValue = '';
    };

    if (hasEdits) {
      window.addEventListener('beforeunload', onBeforeUnload);
    } else {
      window.removeEventListener('beforeunload', onBeforeUnload);
    }
    return () => {
      window.removeEventListener('beforeunload', onBeforeUnload);
    };
  }, [hasEdits]);

  const { backUrlQueryParams } = location?.state ?? {};
  const backUrl = backUrlQueryParams ? `/products?${backUrlQueryParams}` : '/products';

  // flags for which cards to show
  const isSeller = commerceType === ACCOUNT_TYPE_SELLER;
  const productTitle = product && product.title ? product.title : '';

  const isCompanyOtherSeller = company.productPlatform === 'other' && isSeller;

  // can this company edit product data in Modern Dropship?
  // note: does not apply to active, tags, and attributes
  const canEdit = isCompanyOtherSeller;

  const {
    validationErrorMap,
    validationErrorList,
    validationErrorsByBuyer,
    invalidateProductValidationQuery,
  } = useProductValidationErrors({ productId, isSeller });

  useDocumentTitle(`Product Details${productTitle ? ` | ${productTitle}` : ''}`);

  const refreshProduct = () => {
    invalidateProductQuery();
    invalidateProductValidationQuery();
  };

  const onProductDeleted = () => {
    history.replace(backUrl);
    removeProductQuery();
  };

  const onSelectToggled = (selected) => {
    setProductQueryData((data) => ({
      ...data,
      selected,
    }));
    invalidateProductQuery();
  };

  const updatingBuyerProduct = useMutation({
    mutationFn: (data) => patchBuyerProduct(product.id, { ...data, description: data.bodyHtml }),
    onSuccess: (response) => {
      setProductQueryData(response.data);
      onDiscardEdits();
      refreshProduct();
      setUpdating(false);
      addSuccessAlert('Success', 'Your changes have been saved.');
    },
    onError: (err) => {
      setUpdating(false);
      addErrorAlert('Something went wrong', err.message);
      console.error('Unable to save the changes to your product.', err);
    },
  });

  const updatingSellerProduct = useMutation({
    mutationFn: (data) => patchProduct(product.id, data),
    onSuccess: (response) => {
      setProductQueryData(response.data);
      onDiscardEdits();
      refreshProduct();
      setUpdating(false);
      addSuccessAlert('Success', 'Your changes have been saved.');
    },
    onError: (err) => {
      setUpdating(false);
      addErrorAlert('Something went wrong', err.message);
      console.error('Unable to save the changes to your product.', err);
    },
  });

  const onSaveEdits = async () => {
    setUpdating(true);

    if (isSeller) {
      updatingSellerProduct.mutate(edits);
    } else {
      updatingBuyerProduct.mutate(edits);
    }
  };

  const showSeoTitle = !isSeller && product?.seoTitle !== '';

  return (
    <DashboardPage mainContentRef={mainContentRef}>
      <PageContainer>
        {!product && isLoadingProduct ? (
          <LoadingProductPage />
        ) : !product && !isLoadingProduct ? (
          <NoProductPage error={fetchProductError} backUrl={backUrl} />
        ) : (
          <>
            <Prompt
              when={hasEdits}
              message="You have unsaved changes, are you sure you want to leave?"
            />
            <ProductPageHeader
              product={product}
              isSeller={isSeller}
              platform={company.productPlatform}
              hasEdits={hasEdits}
              onDiscardEdits={onDiscardEdits}
              onSaveEdits={onSaveEdits}
              updating={updating}
              backUrl={backUrl}
              onProductDeleted={onProductDeleted}
              onSelectToggled={onSelectToggled}
            />
            <Message show={messageState.show} onClose={hideMessage} kind={messageState.kind}>
              <p className="m-0 mb-1">
                <strong>{messageState.title}</strong>
              </p>
              <p className="m-0">{messageState.message}</p>
            </Message>
            <ProductValidationAlert
              validationErrorsByBuyer={validationErrorsByBuyer}
              validationErrorList={validationErrorList}
              isSeller={isSeller}
            />
            <FlexContainer>
              <Flex.Item grow={3} shrink={2}>
                {canEdit && (
                  <ProductTitleDescription
                    product={product}
                    edits={edits}
                    onEditProductField={onEditProductField}
                    titleValidationErrors={validationErrorMap.title}
                    descriptionValidationErrors={
                      isSeller ? validationErrorMap.bodyHtml : validationErrorMap.description
                    }
                  />
                )}
                <ProductVariants
                  productId={product.id}
                  productTitle={product.title}
                  variants={product.variants}
                  canEdit={canEdit}
                  onEditVariants={onEditArrayField('variants')}
                  clearEditsAt={clearEditsAt}
                  hasEdits={Object.prototype.hasOwnProperty.call(edits, 'variants')}
                  isSeller={isSeller}
                  productPlatform={company.productPlatform}
                  options={product.options}
                  refreshProduct={refreshProduct}
                  validationErrorMapById={validationErrorMap.variants}
                />
                {!canEdit && (
                  <ProductDescription
                    product={product}
                    validationErrors={
                      isSeller ? validationErrorMap.bodyHtml : validationErrorMap.description
                    }
                  />
                )}
                <ProductImages
                  productId={product.id}
                  productTitle={product.title}
                  images={product.images}
                  canEdit={canEdit}
                  onEditImages={onEditArrayField('images')}
                  clearEditsAt={clearEditsAt}
                  hasEdits={Object.prototype.hasOwnProperty.call(edits, 'images')}
                  refreshProduct={refreshProduct}
                  validationErrors={validationErrorMap.images}
                />

                {isProductMetafieldsEnabled && <BuyerMetafields productId={product.id} />}

                <ProductAttributes
                  attributes={product.attributes || {}}
                  canEdit={isSeller}
                  onEditAttributes={onEditObjectField('attributes')}
                  clearEditsAt={clearEditsAt}
                  hasEdits={Object.prototype.hasOwnProperty.call(edits, 'attributes')}
                  validationErrors={validationErrorMap.attributes}
                />
              </Flex.Item>
              <Flex.Item grow={2} shrink={1}>
                <ProductDetails
                  product={product}
                  edits={edits}
                  company={company}
                  isSeller={isSeller}
                  canEdit={canEdit}
                  onEditProductField={onEditProductField}
                />
                <ProductTags
                  product={product}
                  edits={edits}
                  onEditTags={onEditProductField('tags')}
                  canEdit={canEdit && company.productPlatform !== 'csv-sftp'}
                  // validationErrors={validationErrorMap.tags}
                />
                <ProductCategory
                  product={product}
                  edits={edits}
                  canEdit={isSeller}
                  onEditCategory={onEditProductField('googleProductCategory')}
                  validationErrors={validationErrorMap.googleProductCategory}
                />
                <ProductOptions
                  options={product.options}
                  canEdit={canEdit}
                  onEditOptions={onEditArrayField('options')}
                  clearEditsAt={clearEditsAt}
                  hasEdits={Object.prototype.hasOwnProperty.call(edits, 'options')}
                />
                {showSeoTitle && (
                  <Card>
                    <Heading variant="Headings/H3">SEO Title</Heading>
                    <p style={{ marginTop: '15px' }}>{product.seoTitle}</p>
                  </Card>
                )}
              </Flex.Item>
            </FlexContainer>
          </>
        )}
      </PageContainer>
    </DashboardPage>
  );
};

export default ProductPage;
