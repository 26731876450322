import { useState } from 'react';
import { useForm } from 'react-hook-form';
import Modal from 'storybook/stories/cells/Modal';

import Button from 'components/Common/Button';
import FormField from 'components/Common/FormField';
import Message, { useMessage } from 'components/Common/Message';
import { createFulfillment, getOrder } from 'store/slices/ordersV2';

const CreateFulfillmentModal = ({ order, dispatch, onDismiss, showModal }) => {
  // editable copy of items that aren't cancelled
  const [items, setItems] = useState(() => {
    if (!order.items) return [];
    return order.items
      .map((item) => ({
        id: item.sellerItemCode, // ID sent to server for API is supposed to be the sellerItemCode
        sku: item.sku,
        quantity: item.quantity,
        title: item.title,
        cancelled: item.cancelled,
      }))
      .filter((item) => !item.cancelled);
  });
  const [saving, setSaving] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const { showErrorMessage, messageState, hideMessage } = useMessage();

  const onCloseModal = () => {
    setSaving(false);
    hideMessage();
    onDismiss();
  };

  const onSave = (data) => {
    const fulfillment = { ...data };
    setSaving(true);
    if (fulfillment.trackingUrls) {
      fulfillment.trackingUrls = fulfillment.trackingUrls
        .split(',')
        .map((url) => url.trim())
        .map((url) => `https://${url.replace(/https?:\/\//, '')}`);
    } else {
      fulfillment.trackingUrls = [];
    }
    fulfillment.items = items
      .map((item) => ({
        id: item.id.toString(),
        quantity: parseInt(item.quantity || 0, 10),
        sku: item.sku,
      }))
      .filter((item) => item.quantity !== 0);
    dispatch(createFulfillment({ orderId: order._id, fulfillment })).then((action) => {
      if (action.error) {
        showErrorMessage({ title: 'Error creating fulfillment', message: action.error.message });
        setSaving(false);
        return;
      }
      dispatch(getOrder(order._id)).then(() => {
        onCloseModal();
      });
    });
  };

  const onQuantityChange = (id) => (e) => {
    // eslint-disable-next-line no-restricted-globals
    if (isNaN(e.target.value)) return;
    const newItems = items.map((item) =>
      id === item.id ? { ...item, quantity: e.target.value } : item
    );
    setItems(newItems);
  };

  return (
    <Modal.Root
      show={showModal}
      size="md"
      aria-labelledby="create-fulfillment-modal-title"
      centered
      onHide={onCloseModal}
      animation={false}
    >
      <Modal.Header closeButton>
        <Modal.Title>Create a Fulfillment</Modal.Title>
      </Modal.Header>
      <form onSubmit={handleSubmit(onSave)}>
        <Modal.Body>
          <Message
            show={messageState.show}
            onClose={hideMessage}
            kind={messageState.kind}
            className="mt-0"
          >
            <p className="m-0 mb-1">
              <strong>{messageState.title}</strong>
            </p>
            <p className="m-0">{messageState.message}</p>
          </Message>

          <FormField
            label="Carrier"
            id="carrier-input"
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...register('carrier', {
              required: 'You must enter a carrier',
            })}
            error={errors.carrier}
            size="sm"
            placeholder="UPS"
            type="text"
          />
          <FormField
            label="Tracking Code"
            id="tracking-code-input"
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...register('trackingCode', {
              pattern: {
                value: /^[a-zA-Z0-9_\-]+$/, // eslint-disable-line
                message: 'Tracking code can only contain letters, numbers, underscores, and dashes',
              },
            })}
            error={errors.trackingCode}
            size="sm"
            type="text"
          />
          <FormField
            label="Tracking Urls"
            id="tracking-urls-input"
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...register('trackingUrls')}
            error={errors.trackingUrls}
            size="sm"
            type="text"
            subLabel="You can enter multiple urls separated by a comma"
          />

          <p>Items</p>

          {items.map((item, idx) => (
            // eslint-disable-next-line react/no-array-index-key
            <div key={idx + item.id} className="d-flex justify-content-between align-items-center">
              <div className="mb-3">
                <p className="mb-0">{item.title}</p>
                <p className="text-muted small mb-0">SKU: {item.sku || '-'}</p>
              </div>
              <div className="form-group mb-0">
                <input
                  aria-label={`${item.title} quantity`}
                  type="number"
                  className="form-control form-control-sm"
                  value={item.quantity}
                  onChange={onQuantityChange(item.id)}
                />
              </div>
            </div>
          ))}
        </Modal.Body>

        <Modal.Footer>
          <Button size="sm" color="white" type="button" onClick={onCloseModal}>
            Dismiss
          </Button>

          <Button size="sm" type="submit" color="primary" disabled={saving}>
            Save
          </Button>
        </Modal.Footer>
      </form>
    </Modal.Root>
  );
};

export default CreateFulfillmentModal;
